<template>
  <div class="video_and_articleList_container w">
    <video
      class="video"
      controls=""
      preload="auto"
      width="1023"
      height="745"
      src="https://0.rc.xiniu.com/g3/M00/1B/32/CgAH6F34SoyAb8ijCvvGBBnRV24799.mp4"
      poster="@/assets/images/video_cover.png"
    ></video>
    <div class="split_line"></div>
    <div class="article_list">
      <h3 class="article_list_title">更多文章</h3>
      <div class="article_item" v-for="item in article_list" :key="item.id">
        <h3 class="article_item_title">{{ item.title }}</h3>
        <div class="article_item_content">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoAndArticleList',
  props: {
    article_list: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.video_and_articleList_container {
  display: flex;
  padding: 64px 181px;
  .video {
    object-fit: cover;
    margin-top: 44px;
  }
  .split_line {
    height: 785px;
    background: #010343;
    width: 2px;
    margin-left: 20px;
    margin-right: 23px;
    margin-top: 31px;
    opacity: 0.3;
  }
}
.article_list {
  .article_list_title {
    font-size: 32px;
    font-weight: 400;
    color: #010343;
    line-height: 44px;
    margin-left: 19px;
    margin-bottom: 10px;
  }
  .article_item {
    width: 488px;
    height: 127px;
    background: #f7f9fd;
    padding: 15px 43px 0 19px;
    margin-bottom: 29px;
    cursor: pointer;
    &:hover .article_item_title {
      color: #0000ff;
    }
    .article_item_title {
      font-size: 19px;
      font-weight: 400;
      color: #010343;
      white-space: nowrap; /* 禁止换行 */
      overflow: hidden; /* 超出父级的区域隐藏 */
      text-overflow: ellipsis; /* 溢出时用省略号 */
      margin-bottom: 15px;
    }
    .article_item_content {
      font-size: 19px;
      font-weight: 300;
      color: #010343;
      line-height: 27px;
      overflow: hidden; // 超出的文本隐藏
      text-overflow: ellipsis; // 溢出用省略号显示
      white-space: warp; // 溢出不换行
      display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
      -webkit-line-clamp: 2; // 这个属性不是css的规范属性，需要组合上面两个属性，表示显示的行数。
      -webkit-box-orient: vertical; // 从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
    }
  }
}
</style>
