<template>
  <div class="article_container">
    <div class="article_title">{{ article_title }}</div>
    <div class="under_line"></div>
    <div class="article_content" v-html="article_content"></div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    article_title: {
      type: String,
      required: true
    },
    article_content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.article_container {
  padding: 143px 181px 0;
  .article_title {
    font-size: 48px;
    font-weight: 400;
    color: #010343;
    line-height: 67px;
    text-align: center;
  }
  .under_line {
    width: 1556px;
    border: 1px solid #010343;
    margin-top: 21px;
    margin-bottom: 35px;
  }
  .article_content {
    font-size: 32px;
    font-weight: 400;
    color: #010343;
    line-height: 61px;
  }
}
</style>
